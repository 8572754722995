import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import WoodenImg from '../../../assets/images/wooden-gates-img.jpg'

class WoodenGates extends React.Component {
	render() {
		const siteTitle = 'Wooden Gates - Arlington, TX and Dallas-Fort Worth, TX'
		const siteDescription =
			'Are you shopping for a wooden gate for the entryway to your home or business? We design and install custom wood gates in Dallas, Fort Worth, and Arlington.'

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />

				<div className="container-fluid text-center headline gates-headline">
					<div className="container">
						<h1 className="text-white m-0">Wooden Gates</h1>
						<hr />
					</div>
				</div>
				<div className="container d-padding-t d-padding-b access-sys">
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<p>
								Are you looking for a stunning wooden gate for the entryway to
								your property? Do you want an automatic wooden gate that will
								blend seamlessly with your fence? Call J & J Gates Service and
								Design at <a href="tel:8174662794">817-466-2794</a> for expert
								wooden gate installation in Dallas and Fort Worth, TX. Our team
								has years of experience and will make sure you are completely
								satisfied with our work.
							</p>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-8">
							<h4>Endless Creative Options</h4>
							<p>
								<em>
									From rustic ranch-style wooden gates to dark, elegant, and
									modern options, there’s a gate for any style home or business
								</em>
								. Do you want something short or tall? Are you concerned about
								privacy or security, or want your gate to make more of an
								aesthetic statement? You can include wrought-iron accents or go
								with all wood. Whether you need a wooden gate for your ranch or
								your suburban home, J & J Gates Service and Design will help you
								find the perfect gate for your property.
							</p>
							<p>
								There are many concerns to consider when choosing a gate, from
								the dimensions to the style and color. We want your gate to make
								a beautiful first impression for visitors, and make you happy to
								get home at the end of the workday. We also know that many home
								and property owners use gates to ward off animals, unwanted
								human and vehicle traffic, and keep your property secure. Let us
								know what you are looking for and our skilled designers will
								work to accommodate every need.
							</p>
						</div>
						<div className="col-md-4 mt-5">
							<LazyLoadImage
								className="img-fluid mt-5"
								src={WoodenImg}
								alt="Wooden Gate"
							/>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<h4>Skill and Care You Can See</h4>
							<p>
								When we{' '}
								<Link to="/gates/services/gate-installation/">install</Link> or
								repair your wooden gate, we take careful measurements and use
								time-honored skills to make sure your gate will function well
								for years to come. If you need a wooden gate repaired and we did
								not install it, no worries! Our crew will{' '}
								<Link to="/gates/services/gate-repair/">repair</Link> or replace
								any wooden gate.{' '}
								<strong>
									<em>
										Call J & J Gates Service and Design at for wooden gate
										installation in DFW
									</em>
								</strong>
								.
							</p>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default WoodenGates

export const WoodenGatesPageQueryPageQuery = graphql`
	query WoodenGatesPageQueryPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
